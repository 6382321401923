<template>
  <v-dialog v-model="showDialog" :fullscreen="$vuetify.breakpoint.mobile" width="400">
    <v-sheet class="pa-6 pa-md-10" color="white">
      <div class="d-flex gap-10">
        <div>
          <h2 class="mb-5">Complete Invoice</h2>
          <p>Are you sure you want to mark the invoice as paid?</p>
        </div>
      </div>

      <div class="d-flex">
        <v-btn class="mt-5 mx-auto" @click="showDialog = false">Cancel</v-btn>
        <v-btn class="mt-5 mx-auto" color="primary" @click="completeInvoice">Submit</v-btn>
      </div>
    </v-sheet>
  </v-dialog>
</template>

<script>
import {EventBus} from "@/bootstrap/EventBus";
import invoices from "@/api/modules/invoices";

export default {
  name: "CompletePopup",
  data() {
    return {
      showDialog: false,
      invoice: null
    }
  },
  created() {
    EventBus.$on('complete-invoice-popup', invoice => {
      this.invoice = invoice
      this.showDialog = true
    })
  },
  methods: {
    completeInvoice() {
      invoices.setInvoicePaid(this.invoice.id).then(res => {
        this.showDialog = false
        this.$notify({
          title: 'Success',
          type: 'success'
        });
        EventBus.$emit('fetch-invoices')
      }).catch(() => {
        this.$notify({
          title: 'Error occurred',
          type: 'error'
        });
        this.showDialog = false
      })
    }
  }
}
</script>